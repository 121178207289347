import React, { Component } from "react"
import { Link,graphql } from "gatsby"
import SEO from "../components/seo"
import Parser from 'html-react-parser';

class DecouvertePage extends Component {
  render() {
    const data = this.props.data
    const previous = this.props.pageContext.previousPagePath;
    const next = this.props.pageContext.nextPagePath;

    let previousButton;
    if (this.props.pageContext.pageNumber === 0) {
      previousButton = "";
    } else {
      previousButton = <Link className="previous" to={previous}>Précédentes...</Link>;
    }

    let nextButton;
    if (this.props.pageContext.pageNumber > 1 && this.props.pageContext.pageNumber === (this.props.pageContext.numberOfPages - 1)) {
      nextButton = "";
    } else {
      nextButton = <Link className="next" to={next}>Suivantes...</Link>;
    }

    return (
      <>
        <SEO title="Anarchy in the kitchen - Découvertes" description="Mes découvertes à moi !"/>
        
        <div className="maincontent actucontent">
          <section className="header-recette">
            <h2 data-aos="fade">Mes découvertes !</h2>
          </section>
          {data.allWordpressPost.edges.map(({ node }) => (
            <div key={node.slug}>
                <h2  className="center" data-aos="fade-down">
                  <Link to={"../../decouvertes/" + node.slug} dangerouslySetInnerHTML={{ __html: node.title }} >
                    
                  </Link>
                </h2>
                <object data-aos="fade-right" className="hs-img" data={node.jetpack_featured_media_url} type="image/jpg">
                  
                </object>
              <div data-aos="fade">
              {Parser(node.excerpt, {
                replace: function(domNode) {
                  if (domNode.name === 'a') {
                    return (
                      
                      <span>
                        ...
                        <div>
                          <Link to={"../../decouvertes/" + node.slug}><b>Lire la suite....</b> </Link>
                        </div>
                      </span>)
                  }
                }})}
              </div>
              <br/>
              <hr className="wp-block-separator is-style-wide" data-aos="fade-up"/>
              <br/>
            </div>
            
          ))}

          <section className="footer-recette">

            {previousButton}
            {nextButton}

          </section>
          </div>

          
      </>
    )
  }
}

export default DecouvertePage


export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allWordpressPost(
      filter: {categories: {eq: 102395}},
      sort: { fields: date, order: DESC },
      skip: $skip,
      limit: $limit
    ){
    edges {
      node {
        title
        excerpt
        slug
        categories
        date(formatString: "DD/MM/YYYY")
        jetpack_featured_media_url
      }
    }
  }
    
  }
`